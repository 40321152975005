var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignupFooterTopV1 = {
    templateContainer: $('.footer-top-mobile-v1'),

    collateMessages: function (messages) {
      if (Array.isArray(messages)) {
        var len = messages.length;
        var tmp = [];

        for (var i = 0; i < len; i++) {
          tmp.push(messages[i].text);
        }

        return tmp.join('<br />');
      }

      return;
    },

    hideAddressBar: function () {
      window.scrollTo(0, 1);
    },

    initEmailSignup: function () {
      var self = this;
      var $emailContainerNode = this.templateContainer.find('.js-email-signup');
      var $languageIdInput = $();
      var selectedLocale = '';
      var languageSettings = [];
      var languageId = '';

      if (!$emailContainerNode) {
        return null;
      }

      var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
      var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
      var $emailError = $('.js-email-signup__error', $emailContainerNode);
      var $TermsAndConditionsError = $('.js-email-terms__error', $emailContainerNode);
      var $locInput = $('input[name="location"]', $emailContainerNode);
      var $emailAddress = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
      var $emailPromotions = $('input[name="PC_EMAIL_PROMOTIONS"]', $emailContainerNode);
      var $termsAndConditions = $('.js-email-terms-conditions', $emailContainerNode);
      var $emailRedirect = $('.js-redirect-email-signup', $emailContainerNode);
      var location = $locInput.val();
      var destinationPath;
      var emailValue;
      var emailPromotion;

      $languageIdInput = $('input[name="LANGUAGE_ID"]', $emailContainerNode);
      selectedLocale = generic.cookie('LOCALE');
      languageSettings = Drupal.settings && Drupal.settings.common && Drupal.settings.common.translation_locales;

      // Updating the Language ID after the site is translated.
      if (typeof selectedLocale !== 'undefined' && languageSettings) {
        languageId = languageSettings[selectedLocale];

        if (languageId) {
          $languageIdInput.val(languageId);
        }
      }

      $emailForm.once('js-email-signup__form').on('submit', function (submitEvt) {
        submitEvt.preventDefault();
        if (Drupal.settings.elc_common.elc_site_type.coresite) {
          destinationPath = $emailRedirect.length ? $emailRedirect.val() : '';
          emailValue = $emailAddress.val();
          emailPromotion = $termsAndConditions.length > 0 ? $emailPromotions.prop('checked') : 1;
          if (!$emailError.hasClass('hidden')) {
            $emailError.addClass('hidden');
          }
          if ($TermsAndConditionsError.length && !$TermsAndConditionsError.hasClass('hidden')) {
            $TermsAndConditionsError.addClass('hidden');
          }
          if (emailValue !== '' && destinationPath !== '' && emailPromotion) {
            destinationPath = destinationPath + '?email=' + encodeURIComponent(emailValue);
            if (!$emailError.hasClass('hidden')) {
              $emailError.addClass('hidden');
            }
            window.location.href = destinationPath;
          } else {
            if (emailValue === '') {
              $emailError.removeClass('hidden');
            }
            if ($TermsAndConditionsError.length && !emailPromotion) {
              $TermsAndConditionsError.removeClass('hidden');
            }
          }

          return false;
        }
        $emailSuccess.add($emailError).addClass('hidden');
        $emailForm.find('.error').removeClass('error');
        // Transform string into array of form elements
        var params = {};

        $.each($emailForm.serializeArray(), function (index, kv) {
          params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
        });
        // Send the data via a json rpc call
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [params],
          onSuccess: function (jsonRpcResponse) {
            var data = jsonRpcResponse.getData();
            var alreadySignedUp = data.userinfo.previously_opted_in_email_promotion;
            var alreadyPurchased = data.userinfo.previously_purchased;

            if (alreadySignedUp || alreadyPurchased) {
              $(document).trigger('email_signup:success_but_signed_up');
            } else {
              $(document).trigger('email_signup:success_and_new', location);
            }
            if ($emailSuccess.hasClass('inline')) {
              $emailSuccess.removeClass('hidden');
            } else {
              generic.overlay.launch({
                height: 'auto',
                html: $emailSuccess.html()
              });
            }
          },
          onFailure: function (jsonRpcResponse) {
            var messages = jsonRpcResponse.getMessages();

            $emailError.empty().removeClass('hidden');
            $emailContainerNode.find('.error').removeClass('error');
            $.each(messages, function (index) {
              var errorInputfield = messages[index].tags[2].split('field.')[1];

              $('[name="' + errorInputfield.toUpperCase() + '"]', $emailContainerNode)
                .addClass('error')
                .next('.selectBox')
                .addClass('error');
              $emailError.append('<div>' + messages[index].text + '</div>');
            });
          }
        });
      });

      if (!!$termsAndConditions.length && $termsAndConditions.hasClass('slider')) {
        // hide terms + conditions by default
        $termsAndConditions.slideUp(300, function () {
          Drupal.behaviors.grids.equalHeights($emailContainerNode);
        });

        // Show email sign up copy on focus
        $emailAddress.off('focus').on('focus', function (e) {
          $termsAndConditions.slideDown(300, function () {
            Drupal.behaviors.grids.equalHeights($emailContainerNode);
          });
        });

        // hide terms + conditions when clicked outside
        $(document).on('click', function (hideEvt) {
          if (!$(hideEvt.target).closest($emailForm).length && !$(hideEvt.target).is($emailForm)) {
            if ($termsAndConditions.is(':visible')) {
              $termsAndConditions.slideUp(300, function () {
                Drupal.behaviors.grids.equalHeights($emailContainerNode);
              });
            }
          }
        });
      }
    },

    initSMSSignup: function () {
      var self = this;
      var emailContainerNode = this.templateContainer.find('.mobile-signup');

      if (!emailContainerNode) {
        return null;
      }

      var emailFormNode = emailContainerNode.find('form'),
        emailProgressNode = emailContainerNode.find('div.progress'),
        emailSuccessNode = emailContainerNode.find('div.success'),
        emailErrorNode = emailContainerNode.find('div.error'),
        phoneInputNode = emailContainerNode.find("input[name='MOBILE_NUMBER']"),
        acceptInputNode = emailContainerNode.find("input[name='SMS_PROMOTIONS']");

      emailFormNode.once('mobile-signup-form').on('submit', function (submitEvt) {
        submitEvt.preventDefault();

        emailSuccessNode.addClass('hidden');
        emailErrorNode.addClass('hidden');
        emailProgressNode.removeClass('hidden');

        // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces
        var fields = [
          'MOBILE_NUMBER',
          'SMS_PROMOTIONS',
          'CARRIER_CODE',
          'LAST_SOURCE',
          'ORIGINAL_SOURCE',
          'COUNTRY_ID',
          'LANGUAGE_ID'
        ];
        var paramObj = {};

        // loop through all the fields and get the values
        $.each(fields, function (index, value) {
          var formField = $('#mobile-signup input[name=' + value + ']');

          if (formField.is(':checkbox') && !formField.prop('checked')) {
            paramObj[value] = 0;
          } else {
            paramObj[value] = formField.val();
          }
        });

        paramObj['_SUBMIT'] = 'sms_mobile_footer';

        // Send the data via a json rpc call
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            emailProgressNode.addClass('hidden');
            emailSuccessNode.removeClass('hidden');
            $('#mobile-signup').hide();
          },
          onFailure: function (jsonRpcResponse) {
            var messages = self.collateMessages(jsonRpcResponse.getMessages());

            emailProgressNode.addClass('hidden');
            emailErrorNode.html(messages).removeClass('hidden');
          }
        });
      });
    },

    // Hide/show footer signin section based on user status
    initSignin: function () {
      var $signinContainer = $('.js-signin');
      var $myaccountContainer = $('.js-my-account');

      site.signin.isSignedIn =
        typeof site.userInfoCookie == 'undefined' ||
        _.isUndefined(site.userInfoCookie.getValue('signed_in')) ||
        _.isNull(site.userInfoCookie.getValue('signed_in'))
          ? 0
          : parseInt(site.userInfoCookie.getValue('signed_in'));
      if (site.signin.isSignedIn === 0) {
        $signinContainer.show();
        $myaccountContainer.hide();
      } else if (site.signin.isSignedIn === 1) {
        $signinContainer.hide();
        $myaccountContainer.show();
      }
    }
  };

  Drupal.behaviors.emailSignupFooterTopV1 = {
    attach: function (context, settings) {
      site.emailSignupFooterTopV1.initEmailSignup();
      site.emailSignupFooterTopV1.initSMSSignup();
      site.emailSignupFooterTopV1.hideAddressBar();
      site.emailSignupFooterTopV1.initSignin();
    }
  };
})(jQuery);
